import { tv } from "tailwind-variants";

import { SelectSize, SelectVariant } from "../../select.types";

export const buttonClassName = tv({
  base: "flex w-full items-center justify-between gap-4 border-bg-neutral-10",
  variants: {
    size: {
      [SelectSize.xsmall]: "w-auto",
      [SelectSize.small]: "",
      [SelectSize.medium]: "",
      [SelectSize.large]: "",
      [SelectSize.variable]: "",
    },
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "",
      [SelectVariant.filter]:
        "w-auto border-transparent bg-transparent data-[is-open=true]:bg-neutral-20",
      [SelectVariant.withIcon]: "",
    },
  },
});

export const selectedContentContainerClassName = tv({
  base: "flex h-full w-full flex-wrap items-center gap-1 truncate text-sm leading-6",
  variants: {
    size: {
      [SelectSize.xsmall]: "text-xs",
      [SelectSize.small]: "",
      [SelectSize.medium]: "",
      [SelectSize.large]: "",
      [SelectSize.variable]: "",
    },
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "place-content-end",
      [SelectVariant.filter]: "text-sm/6 font-medium text-neutral-primary",
      [SelectVariant.withIcon]: "gap-2",
    },
  },
});
