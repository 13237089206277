import { useEffect, useState } from "react";
import { getClientSubdomain } from "~/utils/auth/get-client-subdomain";

const useClientSubdomain = () => {
  const [subdomain, setSubdomain] = useState<string>();

  useEffect(() => {
    const sd = getClientSubdomain();
    if (sd) setSubdomain(sd);
  }, []);

  return subdomain;
};

export default useClientSubdomain;
