import { NetworkStatus } from "@apollo/client";
import {
  SourceTypeEnum,
  useGetCompanyWithSubdomainQuery,
  useGetSearchResultsQuery,
} from "~/bff/graphql/generated/graphql";
import { useSignedAWSUrl } from "~/hooks/file-upload/use-signed-aws-url";
import useClientSubdomain from "~/hooks/use-client-subdomain";

/**
 * subdomain - the 3rd level domain
 * subdomain might be either workspace or company id
 * useGetCompanyWithSubdomainQuery returns company info in case subdomain is workspace
 * otherwise subdomain is considered as the company id
 */
export const useSubdomainCompanyInfo = () => {
  const subdomain = useClientSubdomain();
  const infoBySubdomain = useGetCompanyWithSubdomainQuery({
    variables: {
      subdomain,
    },
    skip: !subdomain,
  });
  const infoById = useGetSearchResultsQuery({
    variables: {
      companyId: subdomain,
      page: 1,
      limit: 1,
    },
    skip: !subdomain,
  });

  const subdomainInfo = infoBySubdomain.data?.GetCompanyInfo?.at(0);
  const result = infoById.data?.Search?.results.at(0);
  const idInfo = result?.company;
  const companyLogo = subdomainInfo?.CompanyInfo?.logo ?? idInfo?.logo;
  const signedLogo = useSignedAWSUrl(companyLogo);
  const id = subdomainInfo?.id ?? idInfo?.id ?? subdomain;
  const companyName = subdomainInfo?.CompanyInfo?.companyName ?? idInfo?.name;
  const logo =
    result?.source === SourceTypeEnum.External ? companyLogo : signedLogo;

  if (
    !subdomain ||
    infoBySubdomain.networkStatus === NetworkStatus.loading ||
    infoById.networkStatus === NetworkStatus.loading
  ) {
    return;
  }

  return {
    id,
    subdomain,
    companyName,
    logo,
  };
};
