import { useEffect, useRef } from "react";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem
} from "~/scalis-components/core/command";
import { Divider } from "~/scalis-components/core/divider";
import { ScrollArea } from "~/scalis-components/core/scroll-area";
import { Body, BodySize, BodyType } from "~/scalis-components/core/typography";
import { cn } from "~/utils/cn";

import { EmptyList, OptionIcon } from "../";
import { PopoverContentProps } from "./popover-content.types";

export const PopoverContent = ({
  textSize = BodySize.small,
  searchPlaceholder,
  emptyTerm,
  scrollHeight,
  options,
  multi,
  value,
  searchByLabel,
  onChange,
  onInputChange,
  handleSelect = () => {},
  startAdornment,
  optionClassName,
  isOptionDisabled,
}: PopoverContentProps) => {
  const selectedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [value]);

  return (
    <Command>
      {searchPlaceholder && (
        <>
          <CommandInput
            onValueChange={onInputChange}
            placeholder={searchPlaceholder}
            className="h-9"
          />
          <Divider />
        </>
      )}

      <ScrollArea
        className={`max-h-56 overflow-auto p-2 ${scrollHeight ? `h-[${scrollHeight}]` : ""}`}
      >
        {!options?.length && <EmptyList emptyTerm={emptyTerm} />}
        <CommandGroup className="overflow-none p-0">
          {options?.map((option, index) => {
            const isChecked = multi
              ? value?.includes(option.value)
              : option.value === value;
            const isPreviousChecked = multi
              ? value?.includes(options[index - 1]?.value)
              : false;
            const isNextChecked = multi
              ? value?.includes(options[index + 1]?.value)
              : false;
            const isDisabled =
              option.disabled ||
              (isOptionDisabled ? isOptionDisabled(option) : false);

            return (
              <CommandItem
                ref={isChecked ? selectedRef : null}
                value={searchByLabel ? option.label : option.value}
                key={option.value}
                onSelect={() => {
                  if (!isDisabled) {
                    onChange
                      ? onChange(option.value)
                      : handleSelect(option.value);
                  }
                }}
                disabled={isDisabled}
                className={cn(
                  "line-clamp-1 flex cursor-pointer gap-2 rounded-lg p-2 text-sm hover:bg-button-subtle-hover",
                  { "!bg-button-subtle-pressed": isChecked },
                  { "rounded-t-none": isPreviousChecked && isChecked },
                  { "rounded-b-none": isNextChecked && isChecked },
                  { "justify-between": !multi },
                  { "cursor-not-allowed opacity-50": isDisabled },
                  optionClassName,
                )}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {multi && (
                  <div
                    className={cn(
                      "flex size-4 items-center justify-center rounded border border-solid border-bg-neutral-10",
                      isChecked && "border-none bg-neutral-primary",
                    )}
                  >
                    {isChecked && (
                      <i className="fa-solid fa-check fa-xs pt-0.5 text-neutral-on-brand" />
                    )}
                  </div>
                )}

                <span className="flex gap-2 text-sm leading-6">
                  <OptionIcon
                    icon={option.icon}
                    startAdornment={startAdornment}
                  />

                  {option.description ? (
                    <div className="flex flex-col">
                      <Body size={textSize}>
                        {option.label}
                      </Body>
                      <Body
                        size={textSize}
                        type={BodyType.muted}
                      >
                        {option.description}
                      </Body>
                    </div>
                  ) : (
                    <Body size={textSize}>
                      {option.label}
                    </Body>
                  )}
                </span>
                {option.disabled ? (
                  <div>{option.disabledLabel}</div>
                ) : (
                  !multi && isChecked && <i className="fa-regular fa-check"></i>
                )}
              </CommandItem>
            );
          })}
        </CommandGroup>
      </ScrollArea>
    </Command>
  );
};
