"use client";

import jwt from "jsonwebtoken";
import { Session } from 'next-auth';
import { signOut, useSession } from 'next-auth/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConfirmationDialog } from './core';
import { Routes } from '~/src/app/_constants/routes';
import { usePathname, useRouter } from 'next/navigation';
import logger from "~/utils/logger";


export default function SessionTimeout() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: session } = useSession();
  const pathname = usePathname();
  const router = useRouter();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const getCallbackUrl = () => {
    if (!pathname) return "";
    return encodeURIComponent(pathname);
  };

  const handleTokenExpired = async () => {
    await signOut({ redirect: false });
    setIsDialogOpen(true);
  };

  const logoutOnTokenExpiration = useCallback((session: Session) => {
    if (!session?.user?.accessToken) {
      logger.error("Access token is missing.");
      return;
    }

    const decodedToken: { exp?: number } | null = jwt.decode(session.user.accessToken) as { exp?: number } | null;

    if (!decodedToken?.exp) {
      logger.error("Could not extract expiration time from access token.");
      return;
    }
  
    const timeUntilExpiration = decodedToken.exp * 1000 - Date.now();

    if (timeUntilExpiration > 0) {
      const MAX_TIMEOUT = 7 * 24 * 60 * 60 * 1000; // 7 days in ms
  
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(
        () => {
          handleTokenExpired();
        },
        timeUntilExpiration > MAX_TIMEOUT ? MAX_TIMEOUT : timeUntilExpiration
      );
    } else {
      handleTokenExpired();
    }
  }, []);

  useEffect(() => {
    if (session && session.expires) {
      logoutOnTokenExpiration(session);
    }
    
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [session, logoutOnTokenExpiration]);

  return (
    <>
      <ConfirmationDialog
          hasCancelButton={false}
          labelConfirm="Log in"
          message="Please log in again to continue using this app"
          title="Your session has expired"
          onConfirm={() => {
            const callbackUrl = getCallbackUrl();
            let route = Routes.auth.login;
            if (callbackUrl) route = route + `?callbackUrl=${callbackUrl}`;
            router.push(route);
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
      />
    </>
  );
}
