"use client";

import { createContext, useContext, useRef } from "react";

interface CalendarRefsContextProps {
  mainCalendarRef: React.RefObject<any>;
  drawerCalendarRef: React.RefObject<any>;
}

export const CalendarRefsContext = createContext(
  {} as CalendarRefsContextProps,
);

/**
 * 2 refs are required because there are 2 calendar instances
 * and each instances need needs calendar API management.
 */
export const CalendarRefsProvider = ({ children }: React.PropsWithChildren) => {
  const mainCalendarRef = useRef<any>(null);
  const drawerCalendarRef = useRef<any>(null);

  return (
    <CalendarRefsContext.Provider
      value={{
        mainCalendarRef,
        drawerCalendarRef,
      }}
    >
      {children}
    </CalendarRefsContext.Provider>
  );
};

export const useCalendarRefs = () => {
  const context = useContext(CalendarRefsContext);
  if (!context) {
    throw new Error("useCalendarRefs must be used within CalendarRefsProvider");
  }
  return context;
};
