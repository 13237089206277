import { Option } from "../../select.types";

export const getSelected = (value: string | string[] | undefined, options: readonly Option[]) => {
  if (!options?.length) {
    return [];
  }

  if (Array.isArray(value)) {
    return options.filter(option => value.includes(option.value));
  }

  return options.filter(option => option.value === value);
};
