import React, { isValidElement } from "react";
import { AlertProps } from "./alert.types";
import { Body, BodySize, BodyType } from "~/scalis-components/core/typography";

export const Alert = ({ message, title }: AlertProps) => {
  return (
    <div className="w-full rounded-xl bg-brand-primary-light-10 p-4 text-sm">
      <div className="flex gap-2">
        <div>
          <div className="h-full w-[3px] rounded-sm bg-brand-primary-rest" />
        </div>

        <div className="flex-1">
          {title && (
            <Body size={BodySize.small} type={BodyType.emphasys}>
              {title}
            </Body>
          )}
          {}
          {isValidElement(message) ? (
            message
          ) : (
            <Body size={BodySize.small}>{message}</Body>
          )}
        </div>
      </div>
    </div>
  );
};
