import * as React from "react";
import { RadioGroupBase, RadioGroupItem } from "../radio-group-base";
import { IconRadioGroupProps } from "./icon-radio-group.types";
import { cn } from "~/utils/cn";
import { radioGroupStyles } from "./icon-radio-group.styles";

export const IconRadioGroup: React.FC<IconRadioGroupProps> = ({
  options,
  error,
  name,
  ...props
}) => {
  const { container, label, iconContainer, radioButton } = radioGroupStyles({
    disabled: props.disabled,
  });

  return (
    <RadioGroupBase {...props}>
      <div className="flex gap-2">
        {options.map(option => {
          return (
            <label
              key={option.value}
              htmlFor={option.value}
              className={cn(
                container({ checked: option.value === props.value }),
                "hover:bg-neutral-10",
                "active:bg-neutral-20",
                "border border-neutral-10",
                "active:border-brand-primary-rest",
                "[&:focus-within]:border-brand-primary-rest",
                "data-[state=checked]:border-brand-primary-rest",
                props.value === option.value ? "border-brand-primary-rest" : ""
              )}
            >
              <RadioGroupItem
                value={option.value}
                id={option.value}
                aria-valuetext={option.value}
                aria-label={option.label}
                data-testid={option.value}
                className={radioButton()}
              />

              <div className={iconContainer()}>
                <div>
                  <i
                    className={cn(
                      option.icon,
                      props.disabled && "text-neutral-disabled",
                    )}
                    data-testid={option.icon}
                  />
                </div>

                {option.label && (
                  <span className={label()}>{option.label}</span>
                )}
              </div>
            </label>
          );
        })}
      </div>
      {error && (
        <span className="mt-1 inline-block text-xs text-status-critical">
          {error}
        </span>
      )}
    </RadioGroupBase>
  );
};
