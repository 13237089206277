const excludedSubdomains = ["localhost", "www", "scalis", "dev", "stg"];

export const getClientSubdomain = () => {
  if (typeof window !== "undefined") {
    return getSubdomain(window.location.hostname);
  }
};

export const getSubdomain = (host: string) => {
  const parts = host.split(".");
  const potentialSubdomain = parts[0];

  if (!excludedSubdomains.includes(potentialSubdomain)) {
    return potentialSubdomain;
  }

  return undefined;
};
