import Link from "next/link";
import { Body } from "../typography";
import { Icon } from "../icon";
import {
  getButtonLinkBodyColor,
  getButtonLinkBodySize,
} from "./button-link.utils";
import { ButtonLinkProps } from "./button-link.types";
import { buttonLink } from "./button-link.styles";

export const ButtonLink = ({
  size,
  type,
  icon,
  children,
  ...props
}: ButtonLinkProps) => {
  const { base, body, icon: iconClassName } = buttonLink({ type });

  return (
    <Link className={base()} {...props}>
      <Body
        className={body()}
        size={getButtonLinkBodySize(size)}
        color={getButtonLinkBodyColor(type)}
        underline
      >
        {children}
      </Body>

      {icon && <Icon className={iconClassName()} icon={icon} size={16} />}
    </Link>
  );
};
