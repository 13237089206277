import * as React from "react";

import { RadioGroupBase, RadioGroupItem } from "../radio-group-base";

type RadioGroupBaseProps = React.ComponentProps<typeof RadioGroupBase>;

type RadioGroupItemProps = React.ComponentProps<typeof RadioGroupItem>;

export type RadioOption = RadioGroupItemProps & {
  label?: React.ReactNode;
  description?: string;
  tooltip?: string;
  endAdornment?: React.ReactNode;
};

export type RadioGroupProps = RadioGroupBaseProps & {
  options: readonly RadioOption[];
  highlightSelected?: boolean;
  error?: string;
  name?: string;
  type?: RadioGroupType;
  optionsContainerClassName?: string;
  optionContainerClassName?: string;
  buttonClassName?: string;
  labelClassName?: string;
  showBorder?: boolean;
};

export enum RadioGroupType {
  Default = "default",
  Description = "description",
  Outlined = "outlined",
  Highlited = "highlited",
}
