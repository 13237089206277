import { forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import { BodyProps } from "./body.types";
import { bodyClassName } from "./body.styles";
import { cn } from "~/utils/cn";

export const Body = forwardRef<HTMLParagraphElement, BodyProps>(
  (
    {
      asChild,
      size,
      underline,
      italic,
      type,
      center,
      textAlign,
      className,
      style,
      color,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "p";

    return (
      <Comp
        ref={ref}
        style={style}
        className={cn(
          bodyClassName({
            size,
            underline,
            italic,
            type,
            center,
            textAlign,
            color,
          }),
          className,
        )}
        {...props}
      />
    );
  },
);
Body.displayName = "Body";
