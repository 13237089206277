import { DrawerClose } from "../../drawer-group";
import { ButtonIcon } from "../../../button-icon";
import { DialogClose } from "@radix-ui/react-dialog";

interface DrawerCloseButtonProps {
  onClick?: () => void;
}

export const DrawerCloseButton = ({ onClick }: DrawerCloseButtonProps) => {
  return (
    <DialogClose asChild>
      <ButtonIcon 
        icon="fa-solid fa-xmark"
        size="small"
        aria-label="Close drawer"
        onClick={onClick}
      />
    </DialogClose>
  );
};
