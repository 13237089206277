import { Datadog } from "./scalis-datadog";

const isClient = typeof window !== "undefined";

type Logger = {
  error: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
};

let logger: Logger;
if (isClient) {
  logger = {
    error: (...args) => {
      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true") {
        const errorMessage = args
          .map(arg => (arg instanceof Error ? arg.message : String(arg)))
          .join(" ");
        const errorStack = args.find(arg => arg instanceof Error)?.stack;

        Datadog.logs.logger.error(errorMessage, {
          errorStack,
          additionalInfo: args,
        });
      }
      console.error("Client-side error:", ...args);
    },
    info: (...args) => {
      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true") {
        Datadog.logs.logger.info("info", { message: args.join(" ") });
      }
      console.info("Client-side info:", ...args);
    },
    warn: (...args) => {
      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true") {
        Datadog.logs.logger.warn("warn", { message: args.join(" ") });
      }
      console.warn("Client-side warn:", ...args);
    },
  };
} else {
  logger = {
    error: (...args) => {
      console.error("Error needs to be moved to winston logger", ...args);
    },
    info: (...args) => {
      console.info("Info needs to be moved to winston logger", ...args);
    },
    warn: (...args) => {
      console.warn("Warning needs to be moved to winston logger", ...args);
    },
  };
}

export default logger;
