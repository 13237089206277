import { ActionButtonItem } from "../button/action";

interface IconAction {
  icon: string;
  onClick: () => void;
  "aria-label"?: string;
  disabled?: boolean;
}

export interface TextAction {
  text: string;
  onClick: () => void;
  "aria-label"?: string;
}

type Action = IconAction | TextAction;

export function isIconAction(action: Action): action is IconAction {
  return "icon" in action;
}

export interface AccordionProps {
  title?: string;
  titleClassName?: string;
  titleComponent?: React.ReactNode;
  children: React.ReactNode;
  startAdornment?: React.ReactNode;
  actions?: Action[];
  actionButtonItems?: ActionButtonItem[];
  defaultOpen?: boolean;
  collapsible?: boolean;
  padded?: boolean;
  size?: "small" | "medium" | "large" | "xlarge";
  className?: string;
  contentClassName?: string;
}
