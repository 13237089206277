"use client";

import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import * as React from "react";
import { DateRange } from "react-day-picker";
import { cn } from "~/utils/cn";

import { Button, ButtonKind } from "../button";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { DaySelector } from "./components/day-selector";
import { DEFAULT_FORMATING, ONE_MONTH, TWO_MONTHS } from "./date-picker.constants";
import { datePickerClassName } from "./date-picker.styles";
import { DatepickerMode, DatePickerProps } from "./date-picker.types";

export const DatePicker = ({
  date,
  onSelect,
  mode = DatepickerMode.Single,
  className,
  ariaLabel,
  error,
  label,
  required,
}: DatePickerProps) => {
  const [open, setOpen] = React.useState(false);
  const formatSingleDate = (date: Date) =>
    date ? (
      <span className="text-neutral-primary">
        {format(new Date(date), DEFAULT_FORMATING, { locale: enUS })}
      </span>
    ) : (
      <span className="text-neutral-tertiary">Select date</span>
    );

  const formatRangeDate = (date: DateRange) =>
    date && date.from && date.to ? (
      <span className="text-neutral-primary">
        {`${format(date.from, "PP")} - ${format(date.to, "PP")}`}
      </span>
    ) : (
      <span className="text-neutral-tertiary">Select date</span>
    );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <div className="w-full">
        {label && (
          <span className="text-sm/6 font-normal text-neutral-primary">
            {label}
            {required && "*"}
          </span>
        )}
        <PopoverTrigger asChild>
          <Button
            kind={ButtonKind.combobox}
            role="combobox"
            aria-label={ariaLabel}
            disabled={false}
            className={cn(
              datePickerClassName({ error: !!error }),
              "rounded-xl text-left font-normal",
              "hover:bg-neutral-10",
              "active:bg-neutral-20",
              "focus:border focus:border-brand-primary-rest",
              open && "border border-brand-primary-rest",
              className
            )}
          >
            <div className="flex items-center gap-2">
              <i className="fa-regular fa-calendar text-icon-neutral-20" />
              <span>
                {mode === DatepickerMode.Single
                  ? formatSingleDate(date as Date)
                  : formatRangeDate(date as DateRange)}
              </span>
            </div>
          </Button>
        </PopoverTrigger>
        {!!error && (
          <span className="mt-1 text-xs text-status-critical">{error}</span>
        )}
        <PopoverContent className="z-[1100] w-auto rounded-xl bg-white p-0" align="start">
          {/* @ts-ignore */}
          <DaySelector
            mode={mode}
            selected={date as any}
            // @ts-ignore
            onSelect={value => {
              // @ts-ignore
              onSelect(value);
              setOpen(false);
            }}
            disabled={date => date < new Date("1900-01-01")}
            initialFocus
            numberOfMonths={
              mode === DatepickerMode.Range ? TWO_MONTHS : ONE_MONTH
            }
          />
        </PopoverContent>
      </div>
    </Popover>
  );
};
