export enum CaptionType {
  emphasys = "emphasys",
  basic = "basic",
}

export enum CaptionColor {
  primary = "primary",
  secondary = "secondary",
}

export enum CaptionSize {
  large = "large",
}

export interface CaptionProps {
  children: React.ReactNode;
  underline?: boolean;
  italic?: boolean;
  type?: CaptionType;
  color?: CaptionColor;
  asChild?: boolean;
  center?: boolean;
  size?: CaptionSize;
  className?: string;
}
