import {
  MAX_AVATARS,
  MIN_AVATARS,
  AVATAR_GROUP_COLORS_ARRAY,
} from "./avatar-group.constants";
import { getHexColor } from "./avatar-group.utils";
import type { AvatarGroupProps } from ".";
import { Avatar, AvatarProps, AvatarVariantSizeEnum } from "../default";

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
  avatars,
  maxAvatars,
  size,
}) => {
  const maxAvatarFallback = maxAvatars ?? MAX_AVATARS;
  const hasOneAvatar = avatars.length === MIN_AVATARS;
  const sizeFallback = size ?? AvatarVariantSizeEnum.Default;

  const returnAvatars = (
    avatars: AvatarProps[],
    maxAvatars: number,
    size: AvatarVariantSizeEnum,
  ) => {
    const avatarsToDisplay = avatars.slice(0, maxAvatars);
    const hasMoreMaxAvatars = avatars.length > maxAvatars;
    const remainingAvatars = avatars.length - maxAvatars;

    const avatarElements = avatarsToDisplay.map((avatar, index) => {
      return (
        <Avatar
          key={avatar.id}
          {...avatar}
          size={size}
          backgroundColor={getHexColor(index, AVATAR_GROUP_COLORS_ARRAY)}
          className="group-hover:after:bg-black/[0.04] group-active:after:bg-black/[0.08]"
        />
      );
    });

    return (
      <div className="group flex flex-row-reverse -space-x-2 space-x-reverse px-1">
        {hasMoreMaxAvatars && (
          <Avatar
            size={size}
            key={`at-end-avatar`}
            fullName={`+${remainingAvatars}`}
            atEnd={true}
            className="group-hover:after:bg-black/[0.04] group-active:after:bg-black/[0.08]"
          />
        )}
        {avatarElements}
      </div>
    );
  };

  if (hasOneAvatar)
    return (
      <Avatar {...avatars[0]} size={sizeFallback} displayFullName={true} />
    );

  return returnAvatars(avatars, maxAvatarFallback, sizeFallback);
};
