import { RadioGroupBase, RadioGroupItem } from "../radio-group-base";
import { RadioGroupProps, RadioGroupType } from "./radio-group.types";
import { cn } from "~/utils/cn";
import { radioGroupStyles } from "./radio-group.styles";
import { Tooltip, TooltipProvider, DefaultInfoIcon } from "../../tooltip";

export const RadioGroup = ({
  options,
  error,
  name,
  type = RadioGroupType.Default,
  optionsContainerClassName,
  optionContainerClassName,
  buttonClassName,
  labelClassName,
  showBorder,
  ...props
}: RadioGroupProps) => {
  const { container, label } = radioGroupStyles({
    type,
    disabled: props.disabled,
  });

  return (
    <RadioGroupBase {...props}>
      <div
        className={cn(
          {
            "grid auto-cols-fr grid-flow-col gap-2":
              type === RadioGroupType.Outlined,
          },
          optionsContainerClassName,
        )}
      >
        {options.map(option => {
          const id = `${name}-${option.value}`;

          return (
            <label
              key={option.value}
              htmlFor={id}
              className={cn(
                container({ checked: option.value === props.value }),
                `${type === RadioGroupType.Highlited && props.value === option.value ? "bg-slate-100" : ""}`,
                "hover:bg-neutral-10",
                "active:bg-neutral-20",
                showBorder ? "border border-neutral-10" : "",
                "active:border-brand-primary-rest",
                "[&:focus-within]:border-brand-primary-rest",
                "data-[state=checked]:border-brand-primary-rest",
                props.value === option.value ? "border-brand-primary-rest bg-neutral-20" : "",
                "rounded-xl",
                "px-3 py-2",
                "flex items-center"
              )}
            >
              <RadioGroupItem
                value={option.value}
                id={id}
                aria-valuetext={option.value}
                aria-label={String(option.label)}
                data-testid={option.value}
                className={cn(
                  "shrink-0 group aspect-square h-[18px] w-[18px] cursor-pointer overflow-hidden rounded-full",
                  showBorder ? "border border-neutral-10" : "",
                  "[&:focus]:border-brand-primary-rest [&:focus]:outline-offset-0 [&:focus]:outline-0",
                  "data-[state=checked]:border data-[state=checked]:border-brand-primary-rest"
                )}
              />

              <div className={cn("flex flex-col ml-2")}>
                {option.label}

                {option.tooltip && (
                  <TooltipProvider>
                    <Tooltip
                      content={option.tooltip}
                      tooltipTrigger={<DefaultInfoIcon className="ml-1" />}
                    />
                  </TooltipProvider>
                )}

                {option.description && (
                  <span className="select-none text-sm/6 text-neutral-secondary">
                    {option.description}
                  </span>
                )}
              </div>
            </label>
          );
        })}
      </div>
      {error && (
        <span className="mt-1 inline-block text-xs text-status-critical">
          {error}
        </span>
      )}
    </RadioGroupBase>
  );
};
