import { cn } from "~/utils/cn";

import { BadgeProps } from "./badge.types";

export const Badge = ({ done }: BadgeProps) => {
  return (
    <div
      className={cn(
        "h-3 w-3 rounded-full border-4 border-bg-neutral-30 bg-neutral-00",
        {
          "border-brand-primary-dark-hover": done,
        },
      )}
    />
  );
};
