import { tv } from "tailwind-variants";
import { ButtonLinkType } from "./button-link.types";

export const buttonLink = tv({
  slots: {
    base: "group flex items-center gap-[6px]",
    body: "",
    icon: "",
  },
  variants: {
    type: {
      [ButtonLinkType.default]: {
        body: "group-hover:text-button-brand-primary-dark-hover group-active:text-button-brand-primary-dark-pressed",
        icon: "text-button-brand-primary-dark-rest group-hover:text-button-brand-primary-dark-hover group-active:text-button-brand-primary-dark-pressed",
      },
      [ButtonLinkType.critical]: {
        body: "group-hover:text-button-status-critical-hover group-active:text-button-status-critical-pressed",
        icon: "text-button-status-critical-rest group-hover:text-button-status-critical-hover group-active:text-button-status-critical-pressed",
      },
      [ButtonLinkType.disabled]: {
        base: "cursor-default",
        icon: "text-neutral-disabled",
      },
    },
  },
  defaultVariants: {
    type: ButtonLinkType.default,
  },
});
