"use client";

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    Calendar,
    CalendarSettings,
    JobListing as JobListingType,
    useGetCalendarSettingsQuery,
    useGetInterviewerCalendarsQuery,
    useGetJobListingsIdTitleQuery
} from "~/bff/graphql/generated/graphql";

import { useCalendarView } from "./calendar-view-context";
import {
    adjustColorBrightness,
    isExternalEventVisible,
    isJobListingVisible
} from "./context.utils";

import type { EventType } from "./context.types";
export type JobListing = Pick<JobListingType, "id" | "listingTitle">;

interface CalendarEventsContextProps {
  events: any[];
  showExternalEvents: boolean;
  toggleShowExternalEvents: () => void;
  selectedCalendars: string[];
  selectCalendar: (id: string, isChecked: boolean) => void;
  userCalendarSettings?: CalendarSettings;

  companyUsersCalendarSettings?: CalendarSettings[];
  selectedCompanyUserCalendarSettings: string[];
  selectCompanyUserCalendarSettings: (id: string[]) => void;

  resources: any[];

  jobListings: JobListing[];
  selectedJobListings: Array<number>;
  selectJobListing: (key: number, isChecked: boolean) => void;
  isLoading: boolean;
  calendarLoading: (loading: boolean) => void;
}

export const CalendarEventsContext = createContext<CalendarEventsContextProps>({
  isLoading: true,
  calendarLoading: (loading: boolean) => {},
  events: [],
  showExternalEvents: false,
  toggleShowExternalEvents: () => {},
  selectedCalendars: [],
  selectCalendar: () => {},
  resources: [],
  jobListings: [],
  selectedJobListings: [],
  selectJobListing: () => {},
  userCalendarSettings: undefined,

  selectedCompanyUserCalendarSettings: [],
  selectCompanyUserCalendarSettings: (id: string[]) => {},
  companyUsersCalendarSettings: [],
});

export const CalendarEventsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(true);
  const [showExternalEvents, setShowExternalEvents] = useState<boolean>(false);
  const [selectedJobListings, setSelectedJobListings] = useState<Array<number>>(
    [],
  );
  const [selectedCalendars, setSelectedCalendars] = useState<Array<string>>([]);
  const [
    selectedCompanyUserCalendarSettings,
    setSelectedCompanyUserCalendarSettings,
  ] = useState<Array<string>>([]);

  const { isVerticalResources } = useCalendarView();

  const { data: currentUserCallendarSettings } = useGetCalendarSettingsQuery();

  const { data: interviewersData, loading: isLoadingInterviewers } =
    useGetInterviewerCalendarsQuery();
  const { data: jobListingsData, loading: isLoadingJobListings } =
    useGetJobListingsIdTitleQuery();

  const calendarLoading = (loading: boolean) => setIsCalendarLoading(loading);

  const toggleShowExternalEvents = useCallback(
    () => setShowExternalEvents(!showExternalEvents),
    [showExternalEvents],
  );
  // Toggle Calendar
  const selectCalendar = useCallback((id: string, isChecked: boolean) => {
    setSelectedCalendars(prev => {
      if (isChecked) return prev.includes(id) ? prev : [...prev, id];
      else return prev.filter(item => item !== id);
    });
  }, []);

  const selectJobListing = useCallback((id: number, isChecked: boolean) => {
    setSelectedJobListings(prev => {
      if (isChecked) return prev.includes(id) ? prev : [...prev, id];
      else return prev.filter(item => item !== id);
    });
  }, []);

  const selectCompanyUserCalendarSettings = useCallback((id: string[]) => {
    setSelectedCompanyUserCalendarSettings(id);
  }, []);

  // Current user calendar settings (every calendarSettings may contain multiple calendars)
  const userCalendarSettings = useMemo(
    () => currentUserCallendarSettings?.GetCalendarSettings ?? undefined,
    [currentUserCallendarSettings],
  );

  // Other company user calendar settings (every calendarSettings may contain multiple calendars)
  const companyUsersCalendarSettings = useMemo(() => {
    const outerCompanyUsers = interviewersData?.GetCompanyUsers?.filter(
      u =>
        u.CalendarSettings &&
        Number(u.id) !==
          Number(interviewersData?.GetCalendarSettings?.companyUserId),
    );

    // return outerCompanyUsers?.map(user => user.CalendarSettings);
    return outerCompanyUsers
      ?.map(user => user.CalendarSettings)
      .filter((settings): settings is CalendarSettings => settings != null);
  }, [interviewersData]);

  const jobListings = useMemo(() => {
    if (!jobListingsData?.GetJobListing) return [];

    return jobListingsData.GetJobListing.map(job => ({
      ...job,
      color: adjustColorBrightness(job.id),
    }));
  }, [jobListingsData]);

  const getCalendarsToUse = useCallback(() => {
    const userCalendarsToUse = userCalendarSettings?.Calendars?.filter(
      calendar => selectedCalendars.includes(calendar.id),
    );
    const companyCalendarsToUse = companyUsersCalendarSettings
      ?.filter(
        calendar =>
          calendar && selectedCompanyUserCalendarSettings.includes(calendar.id),
      )
      .flatMap(calendar => calendar?.Calendars || []);
    const calendarsToUse = [
      ...(userCalendarsToUse ?? []),
      ...(companyCalendarsToUse ?? []),
    ];
    return calendarsToUse;
  }, [
    companyUsersCalendarSettings,
    userCalendarSettings,
    selectedCalendars,
    selectedCompanyUserCalendarSettings,
  ]);

  /**
   * Transform CalendarEvents to EventSourceInput (fullcalendar type expectation)
   * Add original event and calendar(without events)
   * EventSourceInput:
   * - resourceId
   * - calendarColor
   * - title
   * - start
   * - end
   */
  const events = useMemo(() => {
    // Get calendar sources to display
    const calendarsToUse = getCalendarsToUse();

    // Get events from calendar sources
    const baseEvents =
      calendarsToUse?.flatMap(
        calendar =>
          calendar.CalendarEvents?.map(event => {
            const { CalendarEvents, ...props } = calendar;

            const interviewColor = event.isScalisEvent
              ? adjustColorBrightness(event.regardingJobs)
              : calendar.color ?? undefined;

            const editable = !isVerticalResources && event.isScalisEvent;

            const eventInput: EventType = {
              calendar: props,
              id: event.id,
              originalEvent: { ...event, editable },
              resourceId: calendar.id,
              calendarColor: calendar.color || "",
              interviewColor,
              title: event.title || "",
              start: event.startTime,
              end: event.endTime,
              editable,
            };
            return eventInput;
          }) || [],
      ) || [];

    let filteredEvents = baseEvents.filter(
      isExternalEventVisible(showExternalEvents),
    );
    filteredEvents = filteredEvents.filter(
      isJobListingVisible(selectedJobListings),
    );
    return filteredEvents;
  }, [
    userCalendarSettings,
    companyUsersCalendarSettings,
    selectedCompanyUserCalendarSettings,
    selectedCalendars,
    showExternalEvents,
    selectedJobListings,
    isVerticalResources,
  ]);

  // Only applicable under Schedule Interview Drawer
  const resources = useMemo(() => {
    const calendarsToUse = getCalendarsToUse();

    return calendarsToUse?.map(calendar => ({
      id: calendar.id,
      title: calendar.name,
      calendarColor: calendar.color,
    }));
  }, [
    companyUsersCalendarSettings,
    userCalendarSettings,
    selectedCalendars,
    selectedCompanyUserCalendarSettings,
  ]);

  // Select all user calendars by default to display
  useEffect(() => {
    if (userCalendarSettings?.Calendars?.length) {
      const allCalendarIds = userCalendarSettings?.Calendars.map(
        calendar => calendar.id,
      );
      setSelectedCalendars(allCalendarIds);
    }
  }, [userCalendarSettings?.Calendars]);

  // Select all job listings by default to display
  useEffect(() => {
    if (jobListings?.length) {
      const allJobListingIds = jobListings.map(job => job.id);
      setSelectedJobListings(allJobListingIds);
    }
  }, [jobListings]);

  // Select all company user calendars by default to display
  useEffect(() => {
    if (Array.isArray(companyUsersCalendarSettings)) {
      const allCalendarIds = companyUsersCalendarSettings
        .filter((calendar): calendar is CalendarSettings => calendar != null)
        .map(calendar => calendar.id);
      setSelectedCompanyUserCalendarSettings(allCalendarIds);
    }
  }, [companyUsersCalendarSettings]);

  const isLoading =
    isLoadingInterviewers || isLoadingJobListings || isCalendarLoading;
  const value = useMemo(
    () => ({
      isLoading,
      events,
      showExternalEvents,
      toggleShowExternalEvents,
      selectedCalendars,
      selectCalendar,
      userCalendarSettings,
      resources,
      jobListings,
      selectedJobListings,
      selectJobListing,
      companyUsersCalendarSettings,
      selectedCompanyUserCalendarSettings,
      selectCompanyUserCalendarSettings,
      calendarLoading,
    }),
    [
      isLoading,
      events,
      showExternalEvents,
      toggleShowExternalEvents,
      selectedCalendars,
      selectCalendar,
      userCalendarSettings,
      resources,
      jobListings,
      selectedJobListings,
      selectJobListing,
      companyUsersCalendarSettings,
      selectedCompanyUserCalendarSettings,
      selectedCompanyUserCalendarSettings,
      calendarLoading,
    ],
  );

  return (
    <CalendarEventsContext.Provider value={value}>
      {children}
    </CalendarEventsContext.Provider>
  );
};

export const useCalendarEvents = () => {
  const context = useContext(CalendarEventsContext);
  if (!context) {
    throw new Error(
      "useCalendarEvents must be used within CalendarEventsProvider",
    );
  }
  return context;
};
