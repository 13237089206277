"use client";

import { ButtonLink } from "../button-link";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./notification-group";
import { notificationVariants } from "./notification.styles";
import { useNotification } from "./useNotification";

export const Notification: React.FC = () => {
  const { notifications } = useNotification();

  return (
    <ToastProvider>
      {notifications.map(function ({
        id,
        title,
        description,
        action,
        kind,
        ...props
      }) {
        const { contentContainer, icon } = notificationVariants({ kind });

        return (
          <Toast key={id} {...props}>
            <div className={contentContainer()}>
              <div className="mr-[6px] flex flex-1 items-baseline gap-3">
                <i className={icon()} />
                <div className="flex flex-col gap-2">
                  {(title || description) && (
                    <div>
                      {title && <ToastTitle>{title}</ToastTitle>}
                      {description && (
                        <ToastDescription>{description}</ToastDescription>
                      )}
                    </div>
                  )}

                  {!!action &&
                    (action.href ? (
                      <div className="inline-block">
                        <ButtonLink href={action.href} icon={action.icon}>
                          {action.label}
                        </ButtonLink>
                      </div>
                    ) : (
                      <div
                        className="w-fit cursor-pointer"
                        onClick={action.onClick}
                        aria-label="notification-action"
                        role="button"
                      >
                        <span className="text-sm leading-6 text-brand-primary-rest underline">
                          {action.label}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <ToastClose />
            </div>
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};
