import React from "react";
import { ButtonIconProps } from "./button-icon.types";
import { buttonIcon } from "./button-icon.styles";

export const ButtonIcon = ({
  icon,
  size = "small",
  type = "button",
  variant = "default",
  className,
  label,
  ...buttonAttributes
}: ButtonIconProps) => {
  return (
    <button 
      type={type} 
      className={buttonIcon({ size, variant, className })} 
      {...buttonAttributes}
    >
      <i className={icon} />
      {label && <label>{label}</label>}
    </button>
  );
};
