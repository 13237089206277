import { signOut } from "next-auth/react";
import { cn } from "~/utils/cn";

import ScalisLogo from "../../../logo/scalis-logo";
import { LogoutMenuButton } from "../../../logout-menu-button";

type Props = {
  isLoggedOut: boolean;
  isLoading: boolean;
};

export const NavbarTemplateLogoOnly = ({
  isLoggedOut,
  isLoading,
}: Partial<Props>) => {
  const handleLogout = () => signOut({ callbackUrl: "/" });
  return (
    <div
      className={cn(
        "flex h-[3.313rem] w-full items-center justify-between border-b border-neutral-10 bg-neutral-00 px-4 py-[0.375rem]",
      )}
    >
      <div className="flex w-full items-center gap-6 lg:w-auto">
        <div className="w-24 overflow-hidden">
          <ScalisLogo />
        </div>
      </div>
      <div>
        {!isLoggedOut && !isLoading && (
          <LogoutMenuButton onClick={handleLogout} />
        )}
      </div>
    </div>
  );
};
